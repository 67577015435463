<template>
  <v-form ref="form" lazy-validation>
    <v-card :color="ant" class="form-div">
      <v-card-text class="">
        <v-row>
          <v-col>
            <label for=""> *Assignment Topic:</label>
            <v-text-field
              v-model="topic"
              solo
              outlined
              single-line
              placeholder="Type your topic here"
              :rules="required"
              dense
              :background-color="ant"
              class="search-input"
              required
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row class="">
          <v-col>
            <label for=""> Number of Sources :</label>
            <v-select
              v-model="sources"
              solo
              outlined
              single-line
              placeholder="Required"
              :rules="required"
              dense
              :background-color="ant"
              class="search-input"
              required
              :items="sourcesItems"
            >
            </v-select>
          </v-col>
          <v-col>
            <label for=""> Citation Style:</label>
            <v-select
              v-model="citation"
              solo
              outlined
              single-line
              :rules="required"
              item-text="title"
              item-value="title"
              dense
              :background-color="ant"
              class="search-input"
              required
              :items="citationItems"
            >
            </v-select>
          </v-col>
        </v-row>

        <v-row class="">
          <v-col>
            <label for=""> *Subject</label>
            <v-select
              v-model="subject"
              solo
              outlined
              single-line
              item-text="title"
              item-value="title"
              placeholder="Required"
              :rules="required"
              dense
              :background-color="ant"
              class="search-input"
              required
              :items="subjectItems"
            >
            </v-select>
          </v-col>
          <v-col> </v-col>
        </v-row>
        
        <v-row class="">
          <v-col>
            <label for=""> *Instructions</label>
            <v-textarea
              v-model="instruction"
              solo
              outlined
              placeholder="Type your instructions here"
              :background-color="ant"
            >
            </v-textarea>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-row class="mt-5 ml-2 sticky">
      Estimated Price: ${{ formatAmount(getAmount) }}
      <v-spacer> </v-spacer>
      <v-btn text outlined color="" class="mr-5 px-10" @click="previous" >
        <v-icon>
          mdi-arrow-left
        </v-icon>
        Previous
      </v-btn>
      <v-btn depressed color="primary" class="mr-5 px-10" :loading='loading' @click="orderUpdate">
        Next
        <v-icon>
          mdi-arrow-right
        </v-icon>
      </v-btn>
    </v-row>
  </v-form>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import colorMxn from "@/mixins/colorMxn";
import validationMxn from "@/mixins/validation_mixin";
import generalMxn from "@/mixins/general_mixin";

export default {
  name: "OrderDetails",
  mixins: [colorMxn, validationMxn, generalMxn],
  data() {
    return {
      loading: false,
      sourcesItems: [],
      citationItems: [
        "APA 6th edition",
        "APA 7th edition",
        "Chicago/Turabian",
        "Harvard",
        "MLA",
        "Other",
        "Not Applicable"
      ],
      subjectItems: [
        "Accounting",
        "Advertising",
        "Computer Science",
        "Aeronautics",
        "Architecture"
      ],
      topic: '',
      sources: '',
      citation: '',
      subject: '',
      instruction: '',
    };
  },
  computed: {
    ...mapGetters(['getCurrentComponent', 'getDirectory','getAmount']),
  },
  mounted() {
    this.sourceFunction();
    if (this.$route.name === 'customerDraft') {
      this.prefill();
    }
    this.citationItems = this.getDirectory.styles;
    this.subjectItems = this.getDirectory.subjects;
  },
  methods: {
    ...mapMutations(['setCurrentComponent']),
    previous() {
      this.setCurrentComponent('OrderRequirements')
    }, 
    sourceFunction() {
      var i;
      for (i = 0; i < 30; i++) {
        var item =
          i === 0 ? `${i + 1} Source Required` : `${i + 1} Sources Required`;
        this.sourcesItems.push(item);
      }
    },
    async orderUpdate() {
      this.loading = true;
      if (!this.$refs.form.validate()) {
        this.loading = false;
        return;
      }
      const payload = {
        topic: this.topic,
        sources: this.sources,
        citation: this.citation,
        subject: this.subject,
        instruction: this.instruction,
      };
      const fullPayload = {
        params: payload,
        endpoint: `/orders/${this.$route.params.id}`,
      }
      const response = await this.performUpdateActions(fullPayload);
      if (response.status === 200 ) {
        this.loading = false;
        this.setCurrentComponent('FilesUpload');
      }
      this.loading = false;
    },
    prefill() {
      this.topic = this.getOrderDetail.topic;
      this.sources = this.getOrderDetail.sources;
      this.citation = this.getOrderDetail.citation;
      this.subject = this.getOrderDetail.subject;
      this.instruction = this.getOrderDetail.instruction;
    }
  }
};
</script>

<style scoped>

</style>